<template lang="pug">
  BTabs.tabs-otas(
    fill
    vertical
    nav-wrapper-class="tabs-otas-list"
    :value="tabIndex"
    @activate-tab="handleTabChange"
  )
    BTab(
      v-for="ota in otas"
      lazy
      :disabled="isOtaDisabled(ota)"
      :title="ota.name"
      :title-item-class="`ota-id-${ota.id}`"
      :key="ota.id"
      @click="handleTabSelected(ota)"
    )
      .no-data(v-if="disabled")
        FaIcon.icon(icon="exclamation-triangle")
        | {{ $t("plan_settings.basic_settings.select_shop_and_car_class") }}
      template(v-else)
        Jalan(
          v-if="ota.id === jalanOtaId"
          :validator="validator[ota.rawName]"
        )
        Rakuten(
          v-else-if="ota.id === rakutenOtaId"
          :validator="validator[ota.rawName]"
        )
</template>

<script>
  // mixins
  import withConfirmation from "@/mixins/withConfirmation"

  import { PRICE_MANAGEMENT_ALLOWED_OTA_IDS } from "@/config/constants"
  import { findIndex } from "lodash-es"

  export default {
    props: {
      value: {
        type: Object,
        required: true
      },
      disabled: {
        type: Boolean,
        default: true
      },
      isDirty: {
        type: Boolean,
        default: false
      },
      validator: Object,
      otas: Array
    },

    data() {
      return {
        jalanOtaId: PRICE_MANAGEMENT_ALLOWED_OTA_IDS.jalanId,
        rakutenOtaId: PRICE_MANAGEMENT_ALLOWED_OTA_IDS.rakutenId
      }
    },

    components: {
      Jalan: () => import("./Settings/Jalan"),
      Rakuten: () => import("./Settings/Rakuten")
    },

    mixins: [withConfirmation],

    computed: {
      tabIndex() {
        return findIndex(this.otas, { id: this.value.id })
      }
    },

    methods: {
      isOtaDisabled(ota) {
        return !ota.active || !ota.plan_settings_allowed
      },

      handleTabSelected(ota) {
        this.$conditionalConfirm({
          useConfirm: this.isDirty,
          handler: () => {
            this.$emit("change", ota)
          }
        })
      },

      handleTabChange(_newTab, _oldTab, event) {
        if (this.isDirty) event.preventDefault()
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/variables.sass"

  .tabs-otas
    font-size: 0.8rem
    margin: unset

    .tabs-otas-list
      padding-top: 53px
      min-width: 150px

    .col-auto
      padding-left: unset

    .tab-content
      padding-right: unset

    .no-data
      align-items: center
      color: $default-gray
      display: flex
      justify-content: center
      font-size: 0.8rem
      margin-top: 100px

      .icon
        font-size: 1rem
        margin-right: 10px
</style>
